.bg_light_blue{
    background: #E4EAF6;
    border-radius: 8px;
    padding: 10px;
}
.bg_white_icon{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 6px 15px;
    position: relative;
    top: -1px;
}
.bg_white_icon_add{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 6px 15px;
    position: relative;
    top: -5px;
}
.color_666{
    color: #666666;
}
.color_2929{
    color: #292929;
}
.color_green{
    color: #568B00;
}
.f-i-22{
    font-size: 22px;
}
.color_red{
    color: #D30C00;
}
.bg_white_block{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
}
.f-16{
    font-size: 16px;
}
.f-14{
    font-size: 14px !important;
}
.cursor_pointer{
    cursor: pointer;
}
.flex_block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.color_blue{
    color: #0d6efd;
}
.accordion-item {
    margin-top: 10px;
    border: 1px solid #ddd !important;
}
.bg_white_block1{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px 5px;
}
.map_view_cls{
    border: 1px solid #000;
    padding: 5px 12px;
    border-radius: 7px;
}
.mt_1_9{
    margin-top: 1.9rem;
}
.bg_white{
    background-color: #fff;
}
.block_wise_css{
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    padding: 5px 7px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
}
.pos_top_align{
    position: relative;
    top:-15px
}
.table_custom_programs{
    border-collapse:separate; 
    border-spacing: 0 0.5em;
}
.table_custom_programs thead tr th{
    color: #000000;
    padding: 5px;
    font-size: 14px;
    vertical-align: middle;
    text-align: center !important;
}
.table_custom_programs thead tr th:first-child{
    text-align: left !important;
}
.table_custom_programs tbody tr{
    background-color: #fff;
    margin-top: 10px;
}
.table_custom_programs tbody tr td:first-child{
    text-align: left !important;
}

.table_custom_programs tbody tr td{
    color: #000000;
    font-size: 12px;
    vertical-align: middle;
    text-align: center !important;
}
.ml_5{
    margin-left: 5px;
}
.mr_2{
    margin-right: 2px;
}
.color_yash{
    color: #909090;
}
.bg_ntr_trust_logo{
    background: #F7FEEC;
    border: 1px solid #E4EAF6;
    border-radius: 10px;
    padding: 5px;
}
.bg_red_logo{
    background: #C5312C;
    border-radius: 10px;
    padding: 10px;
}

.social-media-icons {
    display: flex;
}
.social-media-icons li {
    list-style: none;
}
.social-media-icons li a {
    width: 30px;
    height: 30px;
    background-color: red;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    margin: 0 10px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #fff;
}
.social-media-icons li a .icon {
    position: relative;
    transition: 0.5s;
    z-index: 3;
}
.social-media-icons li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
    border: none;
}
.social-media-icons li a:hover:before {
    top: 0;
}
.social-media-icons li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 2;
}
.social-media-icons li:nth-child(2) a:before {
    background: #3b5998;
}
.social-media-icons li:nth-child(3) a:before {
    background: #43d854;
}
.social-media-icons li:nth-child(4) a:before {
    background: #00405d;
}
.social-media-icons li:nth-child(5) a:before {
    background: #3f729b;
}
.social-media-icons li:nth-child(6) a:before {
    background: #55acee;
}
.bg_ntr_trust_logo_white{
    background: #FFFFFF;
    border: 1px solid #E4EAF6;
    border-radius: 10px;
    padding: 5px;
}
.hover_color:hover{
    color: #232f73;
}
.pad_5{
    padding: 5px;
}
.text-underline{
    text-decoration: underline;
    cursor: pointer;
}
.nav-menu-items li{
    font-size: 12px;
}
.f-22{
    font-size: 22px;
}
.upper_space{
    position: relative;
    top: -15px;
    z-index: 10000;
}
.pos_re_z_index{
    z-index: 10000;
    position: relative;
}
.float-right{
    float: right;
}
.pad_left_0{
    padding-left: 0rem;
}
.video_play_css{
    display: block;
    margin: auto;
    position: relative;
    top: 0 !important;
    width: 100%;
    border-radius: 0 !important;
}
.bg_color_yellow{
    background-color: #fff5a1;
    padding: 10px;
    color: #ab1b19;
}
.border_card_r_5{
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.list_voters_info_css{
    list-style: disc;
    font-size: 12px;
    line-height: 30px;
}
.play_icon{position: relative;cursor: pointer;}


.play_icon i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    color: #fff;
    -webkit-transition: opacity .25s ease;
    transition: opacity .25s ease
}
.react-pdf__Document{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #00000029;
    border-radius: 10px;
    padding: 15px;
}
.w_25Perc{
    width: 25%;
}
.pad_tb_10{
    padding: 5px 10px;
}
.text_css{
    text-align: center;
    font-size: 14px;
    line-height: 27px;
    font-weight: 500 !important;
}
.flex-title{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.ln-28{
    line-height: 28px;
}
.f-32{
    font-size: 32px;
}
.height_200{
    height: 200px;
}
.hash_tag_css li{
    display: inline-block;
    background: #FFFFFF;
    border-radius: 5px;
    font-size:13px ;
    color: #DE1D24;
    font-weight: 500;
    text-align: center;
}
.ln_32{
    line-height: 30px;
}
.border-left-right-radius{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.img_pos_support{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 110px;
    top: 5px;
}

.text_decoration_none_a a{
    text-decoration: none;
}
.img_border_bottom_raduis{
    border: 1px solid red;
    border-top-left-radius: 5px;
}
.text_pad_size{
    color: #fff;
    padding: 15px;
    text-align: center;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0px;
}
.height_200{
    height: 180px;
}
.border-bottom-double{
    border-bottom: 3px double #00a652;
}
.modal_pos_top5 >.modal-sm{
    position: relative;
    top: 95px;
}
.target_img_css{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0;
    padding: 15px 15px 5px 15px;
}
.height_201{
    height: 150px;
}
.text_left{
    text-align: left !important;
}
.w-30{
    width: 30%;
}
.height_260{
    height: 260px;
}
.ln-25{
    line-height: 25px;
}
.text-justify{
    text-align: justify;
}
.f-32{
    font-size: 50px;
}
.btn-close{
    position: relative;
    left: -20px;
}
.f-30{
    font-size: 32px;
    line-height: 45px;
}
.pos_bo{
    position: relative;
    top: 20px;
}
.img_border_bottom_raduis_yash{
    border-bottom: 2px solid #ddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.card_yash{
    background: #ddd !important;
    border: 2px solid #d3d3d3 !important;
    border-radius: 10px;
}
.bg_black{
    background-color: #000;
}
.mm_cls{
    width: 115px;
    border-radius: 50%;
    margin-bottom: 15px;
}
.custom_new_card{
    background: transparent !important;
    border: none !important;
    border-radius: 10px;
}
.custom_new_card_body{
    background-color: #AB0A34;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.custom_new_card_border_red{
    background: transparent !important;
    border: 2px solid #AB0A34 !important;
    border-radius: 10px;
}
.custom_new_card_body a{
    text-decoration: none;
}