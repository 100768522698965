.ml-0{
    margin-left: 0px !important;
}
.pos_name_align input{
    position: relative;
    top: -2px;
}
.w_80{
    width: 80%;
    flex: auto;
}
.w_20{
    width: 20%;
    flex: auto;
}
.filter_pos{
    margin-top: 1.8rem;
    margin-right: 0.8rem;
}
.flex_block_vf{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.ml-2{
    margin-left: 5px;
}
.f-13{
    font-size: 13px;
}