.header_cadre_img{
  display: block;
  width: 100%;
  height: 140px;
  background: url('../../images/tdp.jpg') no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 7%;
  border-bottom: 5px solid #db2232;
}
.cursor_pointer label{
  cursor: pointer;
}
.bg_white_shadow_border{
  box-shadow: 0 3px 5px #0000000b;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: .6rem;
  position: relative;
  padding: 15px 10px;
}
.m_left_8{
  margin-left: 8px;
}
.active_checked{
  background-color: #198754;
  color: #fff;
}
.error_cls_new{
  color: red;
  font-size: 12px;
}
.home_btn{
  position: absolute;
  top:97px;
}
.border_yash_pad{
  border: 1px solid #000;
  padding: 12px;
  border-radius: 10px;
  background-color: #eceff4;
}
.text-decoration-underline{
text-decoration: underline;
}
.color_anchor{
color: #337ab7;
}
.input_style input{
width: 1.3em;
height: 1.3em;
font-size: 14px;
border: 2px solid #d3d3d3;
}
.f-28{
font-size: 28px;
}
.pos_top4{
position: relative;
top:4px;
}
.qr_code_pos_card{
position: absolute;
left: 26%;
bottom: 7%;
border: 1px solid #fff;
padding: 1px;
background-color: #fff;
}
.bg_red_card{
background-color: #c02221;
color: #fff;
padding: 3px;
width: 100px;
text-align: center;
}
.flex-card{
display: flex;
flex-direction: row;
justify-content: start;
align-items: start;
}

.member_img{
width: 60px;
height: 60px;
}
.mr_10{
margin-left: 15px;
}
.top100{
top:100px;
}
.ln-25{
line-height: 25px;
}
.red_button_event {
padding: 8px 35px;
font-weight: bold;
background: #EA4420;
border-radius: 18px;
font-size: 14px;
}
.green_button_event{
padding: 8px 35px;
font-weight: bold;
background: #198754;
border-radius: 18px;
font-size: 14px;
}
.flex-back-lang{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: start;
}
.width_90_static_CR {
max-width: 40% !important;
}
.width_10_static_CR {
max-width: 60% !important;
}
.mt-13{
margin-top: 13px;
}
.ml-6{
  margin-left: 3px !important;
}
.span_button{
  background-color: #000;
  color: #fff;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
}
.mb-6{
  margin-bottom: 6rem;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mr-3, .mx-3 {
  margin-right: 0.5rem !important;
}
.border_bottom_yellow{
  border-bottom: 4px solid #ffcb05;
}
.bg_yellow_cadre{
  display: block;
  width: 100%;
  height: 100vh;
  background: url('../../images/bg_two.jpg') no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_red_pad{
  background-color: #ba141a;
  padding: 10px;
  color: #fff;
  margin-bottom: 0;
  font-weight: bold;
}

.bg_red_ass_coup {
  background-color: #ffcb05;
  padding: 5px;
  position: relative;
  top: 0px;
  color: #fff;
  border-bottom: 3px solid #ba141a;
  border-top: 3px solid #ba141a;
}
.bg_red_ass_coup1{
  background-color: #ffcb05;
  padding: 5px;
  position: relative;
  top: 0px;
}
.card_yellow_custom{
  background-color: #ffcb05;
  padding: 15px;
  color: #ba141a;
  border-radius: 0px;
  border: 0px;
}
.w-res-80{
  width: 70px;
}
.border_bottom_red{
  border-bottom: 1px solid #ba141a;
}
.cursor_pointer{
  cursor: pointer;
}

.flex-align-share{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.bg_yash_pad{
  background-color: #fbca2c;
  padding: 25px;
  border-radius: 25px;
}
.title_css{
  font-size: 12px;
  line-height: 21px;
}
.border_bottom_red_new{
  border-bottom: 3px solid #AB0A34;
}
.bor-bottom-yellow-pad{
  border-bottom: 5px solid #fccb4f;
  background-color: #fff;
  padding: 10px;
}
.scanner_css_cs {
  margin-top: 0px;
  cursor: pointer;
  color: red;
}
.box_css{
	border: 1px solid #000;
	padding: 10px;
	border-radius: 5px;
  }
  .box_css h6{
	font-weight: bold;
  }
  .f-13{
	font-size: 13px;
  }
  .width_height_sc{
    width: 100%;
    height: 20px;
  }
  #qr-scanner-membership__scan_region video{
    height: 370px;
    width: 100% !important;
  }
  
  .width_height_cadre_img{
    width: 60px;
    height: 60px;
  }