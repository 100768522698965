.border_pad_ele{
    border:1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
}
.pos_below_align_ec{
    position: relative;
    top:5px;
}
.pos_center{
    position: relative;
    left: 11px;
}