.opacity_05{
    opacity: 0.4;
}

.f-24{
    font-size: 24px;
}
.offical_block{
    background: #FFFFFF;
    border: 1px solid #E4EAF6;
    border-radius: 8px;
    padding: 10px;
}
.flex-social-media-icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.flex-social-media-icons i{
   font-size: 24px;
}
.inst-color{
    color: #9F4079;
}
.youtube-color{
    color: #FF0000;
}
.tw-color{
    color: #37B1E2;
}
.link-color{
    color: #0678B6;
}
.fb-color{
    color: #4B69B1;
}
.cursor_pointer{
    cursor: pointer;
}
.pos_relative{
    position: relative;
}
.bg_white_pad{
    background-color: #fff;
    padding: 15px;
}
.flex-img-style{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.img_round{
    border: 1px solid red;
    border-radius: 35px;
    padding: 5px;
}
.width_col_30{
    width: 30%;
}
.width_col_70{
    width: 70%;
}
.width_50{
    width: 50px;
}
.width_60{
   width: 75px;
}
.mb-0-imp{
    margin-bottom: 0px !important;
}
.img_height_shadow{
    box-shadow: 0px 0px 40px #00000029;
    height: 110px;
}
.ln-32{
    line-height: 32px;
}
.text_pos_sm{
    position: relative;
    left: 8%;
}