
.recorded_block{
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 10px;
}
.flex-audio-voice{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 15px;
}