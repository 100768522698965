.gray{
    color: #000 !important;
}
.flex_block_poll{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.border_serch_option{
    color: #0d6efd;
    border: 1px solid #0d6efd;
    padding: 2px 10px;
    border-radius: 5px;
    cursor: pointer;
}