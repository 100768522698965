
.row.react-bootstrap-table-pagination {
    flex-wrap: nowrap;
  }
  .react-bootstrap-table-pagination-total{
    font-size: 12px;
  }
  @media (max-width: 991px) {
    .row.react-bootstrap-table-pagination {
      text-align: center;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
    }
    .row.react-bootstrap-table-pagination .col-md-6 {
      width: 25%;
    }
    .row.react-bootstrap-table-pagination .col-md-6 .react-bootstrap-table-page-btns-ul {
      float: none;
    }
  }
  
  .react-bootstrap-table table thead{
    background: #ddd;
}
.react-bootstrap-table table thead tr th{
    padding: 10px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
}
.react-bootstrap-table table tbody tr td{
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd !important;
}
.react-bootstrap-table table thead tr th span{
    margin-left: 0;
}
.search-label{
    float: right;
}

#pageDropDown{
    padding: 4px 9px;
    font-size: 12px;
}
.pagination .page-item .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.85rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #1B2430;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
  }
  .pagination .page-item .page-link:hover {
    z-index: 2;
    color: #1B2430;
    text-decoration: none;
    background-color: #dee2e6;
    border: 1px solid #dee2e6;
  }
  .pagination .page-item.active .page-link {
    z-index: 1;
    color: #1B2430;
    background-color: #dee2e6;
    border: 1px solid #dee2e6 !important;
    border-radius: 50%;
  }
  .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
    margin-left: 2px;
    border: none;
  }
  .pagination .page-item:first-child .page-link:hover, .pagination .page-item:last-child .page-link:hover, .pagination .page-item:first-child .page-link:focus, .pagination .page-item:last-child .page-link:focus, .pagination .page-item:first-child .page-link.active, .pagination .page-item:last-child .page-link.active {
    color: #0000ff;
    background: transparent;
    box-shadow: none;
  }
  .pagination_css .react-bootstrap-table-pagination-list{
    width: 100% !important;
    position: relative;
    left: 10px;
    margin-top: 10px;
  }