.bgColorYash{
    background-color: #d3d3d3;
}
.border_none{
    border: none;
}
.react-bootstrap-daterangepicker-container{
    width: 100%;
}
.pos_top_align_EC{
    position: relative;
    top:-44px
}
.instructions_list li{
    font-size: 16px;
    line-height: 25px;
    list-style: decimal;
    padding: 5px;
    color: #fff;
    font-weight: bold;
}
.pad_5_15{
    padding: 10px 15px;
}