.pos_top_cls{
  position: relative;
  top: -12px;
}
.border_bottom_issues h6{
  font-size: 16px;
}
.border_bottom_issues h6::after{
  content: '';
  display: block;
  width: 130px;
  height: 2px;
  background: #e52c1d;
  transition: width .2s;
  margin-top: 5px;
}
.border_bottom_issues2 h6::after{
content: '';
display: block;
width: 175px;
height: 2px;
background: #e52c1d;
transition: width .2s;
margin-top: 5px;
left: 93px;
position: relative;
}
.border_bottom_issues_w_in h6{
font-size: 16px;
}
.border_bottom_issues_w_in h6::after{
content: '';
display: block;
width: 170px;
height: 2px;
background: #e52c1d;
transition: width .2s;
margin-top: 5px;
}

.height100px{
  height: 100px !important;
}
.loader_cls_issue {
  width: 30px;
  height: 30px;
  float: right;
  position: relative;
  top: -34px;
  left: -24%;
}
.pad_4_5{
  padding: 4px 5px;
}
.pos_bottom_5{
  position: relative;
  bottom:5px;
}
.pos_top_5{
  position: relative;
  top:10px;
}
.close_issue_style{
  text-align: left;
  margin-left: 0px;
  padding: 3px 4px;
  border-radius: 5px;
  color: #D30C00;
  text-decoration: underline;
  cursor: pointer;
}

.add_comments_style{
  text-align: left;
  margin-left: 0px;
  padding: 3px 4px;
  border-radius: 5px;
  color: green;
  text-decoration: underline;
  cursor: pointer;
}
.f-10{
font-size: 10px;
}
.width_height_issues{
width: 150px;
height: 100px;
border-radius: 10px;
}
.pdf_width_height{
width: 100%;
height: 120px;
}
.bordered{
border:2px solid #000;
}
.w_30{
max-width: 27%;
}
.w_20{
max-width: 20%;
}
.w_40{
max-width: 38%;
}
.separatehashCls:after{
content: '-';
color: #000;
display: inline-block;
position: absolute;
right: 245px;
top: 340px;
}
.separatehashCls1:after{
content: '-';
color: #000;
display: inline-block;
position: absolute;
right: 171px;
top: 340px;
}
.separatehashCls2:after{
content: '-';
color: #000;
display: inline-block;
position: absolute;
right: 99px;
top: 340px;
}
.height100{
height: 100px;
}
.mt-44{
  margin-top: 2rem;
}