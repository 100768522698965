.leader_bg_color{
    background-color: #dc143c ;
    height: 100vh;
}
.leader_bg_color1{
    background-color: #dc143c ;
}
.w_80{
    width: 150px;
}
.font-style{
    font-style: italic;
}
.flex-align{
    display: flex;
    align-items: center;
    justify-content: center;
}
.input_style input{
    width: 1.3em;
    height: 1.3em;
    font-size: 14px;
    border: 2px solid #d3d3d3;
}
.input_style label{
   margin-left: 7px;
   font-size: 14px;
}

.f-12{
    font-size: 12px;
}
.pad_20_40{
    padding: 20px 40px !important;
}
.f-14{
    font-size: 14px;
}    
.ml-2{
    margin-right: 10px;
}
.width_height_img{
    width: 100%;
    height: 110px;
}
.width_height_img1{
    width: 110px;
    height: 110px;
}
.bg_white_color{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    position: relative;
}
.bg_white_color1{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}
.zIndexCls{
    position: relative;
    z-index: 10000;
}
.tick_red1_css::after{
    top: 75px;
    right: 42%;
    position: absolute;
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #f44646;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.tick_red2_css::before{
    top: 75px;
    right: 12%;
    position: absolute;
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #f44646;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}

.border-color-css{
    opacity: 1;
}
.table_custom_css{
    border-collapse:separate; 
    border-spacing: 0 0.5em;
}
.table_custom_css thead tr th{
    color: #000000;
    font-size: 12px;
    vertical-align: middle;
    text-align: center !important;
    padding: 2px;
}
.table_custom_css tbody tr td{
    color: #000000;
    padding: 2px;
    font-size: 11px;
    vertical-align: middle;
    text-align: center !important;
}
.pad_20_13{
    padding: 20px 12px !important;
}
.red_bg_color{
    background-color: #dc143c ;
}
.cursor_pointer{
    cursor: pointer;
}
.color_black{
    color: #000000;
}
.flex_programs_block{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.border_bottom_my > h6::after {
    content: '';
    display: block;
    width: 90px;
    height: 3px;
    background: #e52c1d;
    transition: width .3s;
    margin-top: 4px;
}
.border_bottom_other > h6::after {
    content: '';
    display: block;
    width: 170px;
    height: 3px;
    background: #e52c1d;
    transition: width .3s;
    margin-top: 4px;
}
.border_black{
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
}
.eventStatusLiCls{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .eventStatusLiCls li{
    display: inline-block;
    font-size: 12px;
    color: #000;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    margin-right: 2px;
    line-height: 25px;
    border-radius: 5px;
  }
  .border_yellow{
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
  }
  .bgColorYash{
    background-color: #d3d3d3;
}
.pad_5_15{
    padding: 10px 15px;
}
.cusor_pointer{
    cursor: pointer;
}
.border-bottom-double{
    border-bottom: 3px double #00a652;
}
.bg_round{
    padding: 1px 9px;
    border: 1px solid green;
    border-radius: 50%;
    color: #fff;
    background-color: green;
    float: right;
}
.f-10{
    font-size: 10px;
}
.pad_5{
    padding: 5px;
}
.border_yellow_color{
    border:2px solid #F9BB37;
}
.tick_green_css{
    position: relative;
}
.tick_green_css::before{
    top: 40px;
    right: 0;
    position: absolute;
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #198754;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
    left: 120px;
}
.tick_red_css::after{
    top: 100px;
    right: 0;
    position: absolute;
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #f44646;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
    left: 45px;
}
.tick_red_css2::before{
    top: 100px;
    right: 45px;
    position: absolute;
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #f44646;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.tick_red_css0::before{
    top: 95px;
    right: 255px;
    position: absolute;
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #f44646;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.tick_yellow_css{
    position: relative;
}
.tick_yellow_css::before{
    top: 40px;
    right: 30px;
    position: absolute;
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #F9BB37;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.get_otp_loading_symbol_cls22{
    width: 20px;
    height: 20px;
}
.radio_button_pos_center input{
    position: absolute;
    right: 35px;
}
.flex-align-css{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.icon_button_pos_center{
    position: relative;
    top: 2px;
}
.border_thick{
    border:2px solid green;
    box-shadow: 0px 0px 40px #00000029;
}
.f-25{
    font-size: 25px;
    font-weight: bold;
}
.pos_icon_check{
    position: absolute;
    content: '';
}
.tick_check_css::after{
    top: 0;
    right: 0;
    position: absolute;
    content: "\f058";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #198754;
    font-size: 25px;
    font-weight: 900;
    line-height: 1;
    left: 85px;
}
.tick_check_css3::before{
    top: 29px;
    right: 0;
    position: absolute;
    content: "\f058";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #198754;
    font-size: 25px;
    font-weight: 900;
    line-height: 1;
    left: 320px;
}
.tick_check_css2::before{
    top: 29px;
    right: 0;
    position: absolute;
    content: "\f058";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #198754;
    font-size: 25px;
    font-weight: 900;
    line-height: 1;
    left: 210px;
}
.flex-align-yes-no{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.f-36{
    font-size: 36px;
}
.border_green{
    border:1px solid green;
    box-shadow: 0px 0px 40px #00000029;
    padding: 15px 20px;
  border-radius: 10px;
}
.border_red{
    border:1px solid red;
    box-shadow: 0px 0px 40px #00000029;
    padding: 15px 20px;
  border-radius: 10px;
}
.z_index_modal{
    z-index: 10000;
}
.ln-20{
    line-height: 22px;
}
.f-16{
    font-size: 14px;
}
.tick_green_modal_css::before{
    top: 128px;
    right: 135px;
    position: absolute;
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #198754;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.tick_red_modal_css::after{
    top: 128px;
    right: 150px;
    position: absolute;
    content: "\f00d";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #f44646;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.tick_green_modal_correct_css::before{
    top: 112px;
    right: 135px;
    position: absolute;
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    display: inline-block;
    color: #198754;
    font-size: 5em;
    font-weight: 900;
    line-height: 1;
}
.border_red_thick{
    border:2px solid red;
    box-shadow: 0px 0px 40px #00000029;
}
.backTOBOtCSS{
    position: absolute;
    content: '';
    left: 25px;
    top: 20px;
    font-size: 22px;
    color: #fff;
}
.slick-track{
    height: 230px;
}
.border_green_new{
    border:1px solid green;
    border-radius: 10px;
}
.border_red_new{
    border:1px solid red;
    border-radius: 10px;
}
.border_correct_with_notMatchedBlock{
    border:1px solid green;
    border-radius: 10px;
}
.flex-image-name{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.w_40_LS{
    width: 30%;
}
.w_60_LS{
    width: 70%;
}
.hideCls{
    display: none;
}