.bg_css_volunter_logo{
  display: block;
  width: 100%;
  height: 150px;
  background: url('../../images/header.png') no-repeat;
  background-position: 155px 5px;
  background-repeat: repeat;
}
.back_button{
  position: relative;
  top: -43px;
}
.pos_relative{
  position: relative;
}
.w_90_R{
  width: 70px;
}
.w_60_R{
  width: 60px;
}
.input_size{
  font-size: 18px;
  position: relative;
  right: -3px;
  top: 3px;
}
.slide_one_css{
  transition-duration: .5s;
  transition-property: transform, opacity;
}
.slideLeft {
  transform: translate(-100%, 0);
  opacity: 0;
}
.f-28{
  font-size: 28px;
}
.scanner_css{
  margin-top: -10px;
  cursor: pointer;
  color: green;
}
.view_enroll_css{
  text-decoration: underline;
  color: red !important;
  cursor: pointer;
}
.text_center{
  text-align: center;
}
.m_top50{
  margin-top: 40px;
}
.f-34{
  font-size: 140px;
}
.f-10{
  font-size: 11px;
}
.flex-interests-align{
display: flex;
flex-direction: row;
align-items: center;
}
input::placeholder{
font-size: 12px;
}
.mt_1{
margin-top: -3px;
}
.f-20{
font-size: 20px;
}
.ml_28{
margin-left: 10px;
}

#house_water_id,#ptr_id,#bc_id{
margin-right: 5px;
}
.ml_15{
margin-left: -0px;
}
.w_55_R{
width: 55px;
}
.mahashaktiPosCls{
position: relative;
top: -10px;
}
#ad_id{
position: relative;
top: 15px;
}
.pos_left_align{
position: relative;
left: 15px;
}
.loader_cls_otp{
width: 20px;
height: 20px;
float: right;
position: relative;
top: -25px;
left: -18px;
}
.otp_message_Cls{
float: right;
position: relative;
top: -60px;
left: -18px;
}
.otp_message_Cls::before{
position: relative;
left: -9px;
}
.flex_otp_msg_align{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
.get_otp_loading_symbol_cls{
width: 20px;
height: 20px;
float: right;
position: relative;
top: 12px;
}
.color_yash_white div button {
border: medium none !important;
background: rgb(0, 0, 0) !important;
color: rgb(255, 255, 255) !important;
font-size: 13px !important;
line-height: 22px !important;
border-radius: 5px;
padding: 4px 15px;
}
.color_yash_white div button span{
margin-left: 0px;
}
.line_height_20{
line-height: 20px;
}
.countdown-text {
display: flex;
align-items: center;
justify-content: space-between;
}
.qr-image-wrapper section { 
border-radius: 16px;                   
}

.qr-image-wrapper section div {
border: unset !important;     
box-shadow: unset !important;   
}


.ocrloader p::before {
content: '';
display:inline-block;
width: 12px;
height: 12px;
border-radius: 50%;
background: #b50a12;
position: relative;
right: 4px;
}
.ocrloader p {
color: #b50a12;
position: absolute;
bottom: -30px;
left: 38%;
font-size: 16px;
font-weight: 600;
animation: blinker 1.5s linear infinite;
font-family: sans-serif;
text-transform: uppercase;
}

.ocrloader {
position: relative;
padding: 15px;
}

.ocrloader span {
position: absolute;
left: 5px;
top: 0;
width: 85%;
height: 2px;
background-color: #b50a12;
box-shadow: 0 0 7px 1px #b50a12,
            0 0 1px 1px #b50a12;
z-index: 1;
transform: translateY(95px);
animation: move 1.7s cubic-bezier(0.15,0.54,0.76,0.74);
animation-iteration-count: infinite;
}

.ocrloader:before,
.ocrloader:after,
.ocrloader em:after,
.ocrloader em:before {
border-color: #b50a12;
content: "";
position: absolute;
width: 45px;
height: 46px;
border-style: solid;
border-width: 0px;
}
.ocrloader:before {
left: 0;
top: 0;
border-left-width: 5px;
border-top-width: 5px;
border-radius: 5px 0 0 0;
}
.ocrloader:after {
right: 0;
top: 0;
border-right-width: 5px;
border-top-width: 5px;
border-radius: 0 5px 0 0;
}
.ocrloader em:before {
left: 0;
bottom: 0;
border-left-width: 5px;
border-bottom-width: 5px;
border-radius: 0 0 0 5px;
}
.ocrloader em:after {
right: 0;
bottom: 0;
border-right-width: 5px;
border-bottom-width: 5px;
border-radius: 0 0 5px 0;
}

@keyframes move {
0%,
100% {
  transform: translateY(190px);
}
50% {
  transform: translateY(0%);
}
75% {
  transform: translateY(160px);
}
}

@keyframes blinker {  
50% { opacity: 0; }
}
.input_size_MS{
font-size: 18px;
position: relative;
right: -3px;
top: -12px
}
.input_size_AD{
font-size: 18px;
position: relative;
right: -11px;
top: 3px;
}
.main_text_pos{
left: 76px;
top: 67px;
}
.cardName_pos{
position: relative;
top: 18px;
left: 50px;
}
.cardPhone_pos{
position: relative;
left: 80px;
top: 19px;
}
.cardCons_pos{
position: relative;
left: 90px;
top: 19px;
}
.cardId_pos{
position: relative;
left: 50px;
top: 16px;
}
.f-9{
font-size: 9px;
margin-left: 0px !important; 
}
.ln-30{
line-height: 25px;
}
.qr_code_pos{
position: absolute;
top: 110px;
left: 18px;
}
.get_otp_loading_symbol_cls1{
width: 20px;
height: 20px;
float: right;
position: relative;
top: 1px;
}
.pos_top_m{
position: relative;
top: 5px;
}
.pagination_none .react-bootstrap-table-pagination{
display: none;
}
.f-15{
font-size: 15px;
}
.f-42{
font-size:5rem;
}
.modal_css_limit{
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 40px #00000029;
border-radius: 10px;
position: relative;
top:25px;
border: 1px solid red;
left: 20px;
width: 90%;
}
.ln_30{
line-height: 25px;
}
.width_90_static{
max-width: 65% !important;
}
.width_10_static{
max-width: 35% !important;
}
.get_otp_loading_symbol_cls22{
width: 20px;
height: 20px;
float: right;
position: relative;
top: 0px;
left: 4px;
}

iframe img {
width: 100%;
}
.ml_left0{
margin-left: 0px !important;
}

fieldset{
border-radius: 10px;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
margin-top:20px;
border: 1px solid #000;
padding: 10px;
background: #fff;
color:#000;
box-shadow: 0px 0px 8px rgba(0,0,0,0.16);
-moz-box-shadow: 0px 0px 8px rgba(0,0,0,0.16);
-webkit-box-shadow: 0px 0px 8px rgba(0,0,0,0.16);
}
fieldset legend{
color: #FFA0C9;
border-top: 1px solid #000;
border-left: 1px solid #000;
border-right: 1px solid #000;
border-radius: 5px 5px 0px 0px;
-webkit-border-radius: 5px 5px 0px 0px;
-moz-border-radius: 5px 5px 0px 0px;
background: #fff;
color:#000;
padding: 0px 8px 3px 8px;
box-shadow: -0px -1px 2px #F1F1F1;
-moz-box-shadow:-0px -1px 2px #F1F1F1;
-webkit-box-shadow:-0px -1px 2px #F1F1F1;
font-weight: bold;
font-size: 14px;
width: auto !important;
position: relative;
top: -18px;
margin-bottom: 0px;
}
.line-height-24{
line-height: 24px;
}
.f-34{
font-size: 34px;
}
.ln-40{
line-height: 22px;
}
.list-stylelocation li{
font-size: 12px;
list-style: decimal;
line-height: 22px;
font-weight: bold;
}
.dot-slashed::before{
content: "/";
position: absolute;
font-size: 45px;
top: 6px;
}
.pos_left_location{
position: relative;
left: -5px;
}
.w_60{
max-width: 55%;
}
.w_40{
max-width: 45% ;
}
.pad_5{
padding: 5px;
}
.houseVisitCaptureCls > .modal-dialog{
background: #d3d3d3 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 40px #00000029;
border-radius: 10px;
padding: 10px;
}