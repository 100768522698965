
  
  .navbar {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding: 0px;
  }
  .navbar i{
    color: #000;
  }
  .center-auto{
    margin: auto;
    display: block;
  }
  .bg_yellow_color{
    background-color: #F9BB37;
  }
  .border_left_right_top_radius{
    border-top-right-radius: 10px;
  }
  .color_black{
    color: #000000;
  }
  .cross_icon_pos{
    position: relative;
    top: 2px;
    font-size: 20px;
  }
  .f-14{
    font-size: 14px;
  }
  
  .nav-menu-items a{
    text-decoration: none;
    color: #000000;
  }
.ln_20{
  line-height: 20px !important;
}
  .nav-menu-items li{
    line-height: 10px;
    padding: 10px 20px;
  }
  .nav-menu-items li:hover{
   background-color: #d3d3d3;
   -webkit-transition: all .4s ease;
   transition: all .4s ease
  }

  .sub-menu-items li{
    line-height: 10px;
    padding: 10px 15px;
  }
  
  .sub-menu-items li:hover{
    background-color: #D30C00;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
   }
  .sticky-image-wrapper{
    position: fixed;
    bottom: 0;
    width: 100%;
}

  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    width: 315px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    background: #FFFFFF;
    box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
    padding: 0;
  }
  
  .navbar-toggle {
    background-color: var(--navBg);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  .logo_font_size{
    font-size: 16px;
    position: relative;
    left: 8%;
    top: 10%;
    font-weight: bold;
  }
  .hideComponent{
    display:none;
  }
  

  @media screen and (max-width: 767px) {
    .sticky-image-wrapper{
      position: relative;
      bottom: 0;
      width: 100%;
  }
  }
  .flex-header{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .z_index{
    z-index: 100000;
  }
.hideNavBarBottomCls{
  display: none;
}
.scroll_navBar_cls{
  overflow-y: scroll;
  height: 100vh;
}
.margin_top_20{
  margin-top: -55px;
}
.image_blur{
  filter: blur(2px);
}
.card_bottom_20{
  bottom: 21px;
}
.cursor_pointer{
  cursor: pointer;
}
.ml-0{
  margin-left: 0px;
}
.header_img{
  display: block;
  width: 100%;
  height: 200px;
  background: url('../images/TDP_JSP.png') no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 40%;
  margin-bottom: 15px;
}
.header_img1{
  display: block;
  width: 100%;
  height: 120px;
  background: url('../images/shankaravam.jpeg') no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 7%;
}
.header_img1 img{
width: 55px;
}
.header_img1 h5{
  text-transform: uppercase;
  }
.mt-45{
  margin-top: 4.5rem;
}
.back_button1
{
  position: relative;
  top: -39px;
}
.logo_font_size1{
  font-size: 16px;
  position: relative;
  left: 4%;
  top: 4%;
  font-weight: bold;
}

.header_img_p{
  display: block;
  width: 100%;
  height: 90px;
  background: url('../images/bg.png') no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 40%;
}
.header_img_p img{
  width: 55px;
}
.header_img_p h5{
  text-transform: uppercase;
  }
  .boder_pad_yellow{
    background-color: #f7be29;
    padding: 3px 5px;
  }
  .height_bg{
    height: 165px;
    background-color: #fffdf1;
    border-bottom: 4px solid #c94c36;
  }
  .header_img_align{
    width: 100% !important;
  display: block;
  margin: auto;
  height: 120px;
  position: relative;
  top: -13px;
  }

  .new_block_css{
    background-color: red;
    color: #fff;
    padding: 1px 5px;
    font-size: 10px;
    border-radius: 10px;
    position: relative;
    top: -1px;
    margin-left: 5px !important;
  }
  .m_right_left_18{
    margin-left: 18px;
  margin-right: 18px;
  }
  .width_190{
    width: 190px;
  }
  .width_55{
    width: 40px;
    position: relative;
    top: 4px;
  }
  .width_65{
    width: 55px;
  }
  .bg_new_yellow_clr{
    background-color: #FCDF05;
  }
  .header_bottom_red{
    border-bottom: 5px solid #AB0A34;
  }
  .line-height-cls li{
    line-height: 20px;
  }