.m-l-0{
    margin-left: 0px !important;
}
.ml-22{
    margin-left: 3px !important;
}
.f-11{
    font-size: 11px !important;
}
.f-12{
    font-size: 12px;
}
.ln-22{
    line-height: 22px;
}
.border_yash_block{
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}
.border_yash_block1{
    border: 1px solid #ddd;
}
.border-dashed {
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    padding: 15px 15px 10px 15px;
  }
  .bg_round_new{
    padding: 1px 7px;
    border: 1px solid green;
    border-radius: 50%;
    color: #fff;
    background-color: green;
}

.color-ea-blue{
	color: #1d4999 !important;
}
.color-ea-yelllow{
	color:#d29500;
}
.color-ea-green{
	color: #4a8522;
}
.color-ea-orange{
	color: #be5108;
}
.width_80{
    width: 80px;
}
.mt-top-30{
    margin-top: 30px;
}
.flex_block_evenly{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}
.photos_flex {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: center;
  }