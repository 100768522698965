.white_block_with_left_border{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px;
}
.border_left_green{
  border-left: 10px solid #68A00B;
  border-radius: 8px 0px 0px 8px;
}
.border_left_yellow{
  border-left: 10px solid #F9BB37;
  border-radius: 8px 0px 0px 8px;
}
.border_left_black{
  border-left: 10px solid #e52c1d;
  border-radius: 8px 0px 0px 8px;
}
.flex_PA{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex_programs_block{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.bg_red_event{
  background: radial-gradient(95% 272.15% at 92.69% 13.25%, #FC7D36 3.71%, #E41B18 100%);
  padding: 10px;
  width: 100%;
}
.flex_event_name_top{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.location_css{
  background: #F9BB37;
  border-radius: 4px;
  padding: 5px 10px;
}
.circle_black{
  background: #F9BB37;
  opacity: 0.8;
  border-radius: 10px;
  padding: 4px;
  border: 1px solid #000;
}
.mr-2{
  margin-right: 5px;
}
.color_black_no_underline{
  text-decoration: navajowhite;
  color: #000;
}
.idem_img_pos{
  width: 100%;
  height: 350px;
}

.red_button_event{
  padding: 10px 65px;
  font-weight: bold;
  background: #EA4420;
  border-radius: 18px;
  font-size: 14px;
}
.mt-5{
  margin-top: 2rem !important;
}
.float-end{
  float: right !important;
}
.dots_icon{
  background-color: #E52C1D;
  padding: 8px 12px;
  border-radius: 20px;
}
.dots_icon i{
  color: #fff;
}
.event_material_dots_popup li{
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  cursor: pointer;
}
.event_material_dots_popup li:hover{
  color: #EA4420;
}
.f-18{
  font-size: 18px;
}
.icon_width_height{
  width: 20px;
  height: 20px;
}

.border_bottom_my > h6::after {
  content: '' !important;
  display: block !important;
  width: 175px !important;
  height: 3px !important;
  background: #e52c1d !important;
  transition: width .3s !important;
  margin-top: 10px !important;
}
.border_bottom_other > h6::after {
  content: '';
  display: block;
  width: 160px;
  height: 3px;
  background: #e52c1d;
  transition: width .3s;
  margin-top: 4px;
}
.show_block{
  display: block;
}
.hide_block{
  display: none;
}
.cusor_pointer{
  cursor: pointer;
}
.showImgCaptureDiv{
  background: #fff;
  width: 100%;
}
.showImgCaptureDiv div div{
  top:35% !important;
  border:4px solid #fff !important;
}
video{
  display: block;
  margin: auto;
  border-radius: 35px;
  position: relative;
  top: 2%;
  width: 95%;
}
.img_width_height{
  width: 100%;
  height: 50px;
  border:1px solid #000;
}
.border_img{
  border: 2px solid green;
  padding: 6px 10px;
  border-radius: 8px;
  margin-left: 5px;
}
.flex_img{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-content: center;
}
.camara_icon{
  text-align: center;
  color: green;
  font-size: 45px;
  cursor: pointer;
  transform: translateY(-10%);
}
.photos_gallery_flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-content: center;
}
.photos_gallery_flex div{
 margin-left: 10px;
 margin-top: 10px;
 
}
.photos_gallery_flex button{
  margin-left: 10px;
  margin-top: 10px;
}
.width_height_photo{
  width: 100px;
  height: 70px;
  border-radius: 10px;
}
.view_more{
  background: #F6F6F6;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #d3d3d3;
  font-size: 14px;
}
.drop-container {
  position: relative;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110px;
  padding: 5px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
.remove_file{
  float: right;
  position: relative;
  top: 11px;
  left: 1px;
  color: red;
  font-size: 18px;
  font-weight: bold;
}
.event_material_block{
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px;
  width: 150px;
  text-align: center;
}
.eventStatusLiCls{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #000;
  border-radius: 5px;
  opacity: 1;
  padding: 3px;
}
.eventStatusLiCls li{
  display: inline-block;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  margin-right: 2px;
  line-height: 30px;
  border-radius: 5px;
  width: 50%;
}
.eventStatusLiCls li:hover{
  background: #e52c1d;
  color: #fff;
  transition: .5s all ease-out;
  -webkit-transition: .5s all ease-out;
  -moz-transition: .5s all ease-out;
}
.eventStatusLiCls li.active {
  background: #e52c1d;
  color: #fff;
  transition: .5s all ease-out;
  -webkit-transition: .5s all ease-out;
  -moz-transition: .5s all ease-out;
}
.switch_camara{
  float: right;
  font-size: 12px;
  background: red;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.back_css{
  float: right;
  background-color: red;
  padding: 5px;
  margin-right: 10px;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.campaignAttendedDtsCls{
  cursor: pointer;
  color: #0d6efd;
}
.border_black{
  border: 1px solid #000;
}
.pad_bottom{
  padding: 3px 10px;
  margin-bottom: 5px;
}
.accordion-header button{
  font-weight: 700;
  font-size: 14px;
}
.pos_top{
  transform: translateY(-17%);
}
.event_headings_color{
  color: #0d6efd;
}
.flex-box-options {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.questionTypePos{
  position: relative;
  top: 18px;
}
.optionTypeCls{
  position: relative;
  right: -8px;
}
.bg_red_events{
  background: #e52c1d;
  padding: 6px;
 
}
.pad_10_5{
padding: 5px 10px;
}
.button_red{
color: #e52c1d;
border-color: #ffc107;
}
.red{
background: #e52c1d !important;
color: #fff !important;
}
.yellow{
background: #f9bb37 !important;
color: #000 !important;
}
.green{
background: #68a00b !important;
color: #fff !important;
}
.border_pad {
border-radius: 3px;
padding: 10px;
background-color: #E3E3E3;
}
.flex-box-Status{
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
}
.f-13{
font-size: 13px;
}
.m_top45_right_40{
margin-top: 40px;
margin-right: 45px;
}
.pad_3_15{
padding: 3px 15px;
}
.flex_block_MLC{
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
}
.width_100{
width: 100px;
}
.border_red_dark input{
border: 3px solid red;
font-size: 20px;
}
.pos_capture_image{
top: -15px;
right: -12px;
}
.image-gallery-bullets{
display: none;
}
.w_60{
width: 60px;
}
.color_anchor{
color: #0d6efd !important;
}
.hover_cls{
padding: 5px;
cursor: pointer;
font-size: 13px;
}
.hover_cls:hover{
color: green;
}
.color_orange{
color: #34495e ;
}