.ml-0{
    margin-left: 0px !important;
}
.pos_name_align input{
    position: relative;
    top: -2px;
}
.w_80{
    width: 80%;
    flex: auto;
}
.w_20{
    width: 20%;
    flex: auto;
}
.filter_pos{
    margin-top: 1.8rem;
    margin-right: 0.8rem;
}
.flex_block_vf{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.ml-2{
    margin-left: 5px;
}
.f-13{
    font-size: 13px;
}
.cursor_pointer{
    cursor: pointer;
}
.height20px{
    height: 20px;
}
.pos_left_cs{
    position: relative;
    right: 10px;
}
.whatsApp_pad{
    padding: 3px 4px;
}
.sms_pad{
    padding: 3px 20px;
}

.isVoteChecked{
    border: 1px solid #000;
    background-color: #fff;
    padding: 7px 10px;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 0px;
}
.isVoteChecked input{
   margin-right: 5px;
   position: relative;
    top: 4px;
}
.flex-align-radiobuttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
}
.m-lr{
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    top: 2px;
}