.logo_font_MF_size {
    font-size: 18px;
    position: relative;
    left: -2%;
    top: 5%;
    font-weight: bold;
  }
  .w-80P{
    width: 135px;
  }
  .pad5_14{
    padding: 4px 15px;
  }
  .carousel-indicators button{
    background-color: red !important;
    position: relative;
    top:20px;
  }
  .f-35{
    font-size: 35px;
  }
  .card-header img{
    height: 70px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
 
  .card{
    background: #FFFFFF;
    border: 1px solid red;
    border-radius: 10px;
  }
  .bg_css_logo{
    display: block;
    width: 100%;
    height: 170px;
    background: url('../../images/header.png') no-repeat;
    background-position: 155px 5px;
    background-repeat: repeat;
  }
  .back_button{
    position: relative;
    top: -43px;
  }