@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,700,700i');

body {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  overflow-x: hidden;
  font-family: "Montserrat",sans-serif !important;
  width:100%;
  color: #000000;
}

.spinner-border {
  display: block !important;
  margin: auto;
  width: 2rem;
  height: 2rem;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}
.color_yellow{
  color: #f9bb37;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}