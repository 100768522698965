.bg_red{
    background-color: rgba(0,0,0,0.8);
    padding: 5px;
    position: relative;
    top: 0px;
    margin-bottom: 15px;
    
    
}
.color_white{
    color: #fff;
}
.color_000{
    color: #000;
}
.color_c3c3c3{
    color: #c3c3c3;
}
.color_green{
    color: green;
}
.f-14{
    font-size: 14px;
}
.mt-40{
    margin-top: 40px
}
.f-12{
    font-size: 12px;
}
.bg_light_blue{
    background: #E4EAF6;
    border-radius: 8px;
    padding: 6px;
}
.table_custom thead tr th{
    background: #A7B5D2;
    color: #000000;
    padding: 5px;
    font-size: 12px;
    text-align: center;
}
.table_custom tbody tr td{
    color: #000000;
    padding: 3px;
    font-size: 11px !important;
    text-align: center;
    vertical-align: middle;
}

.f-9{
    font-size: 9px;
}

.cursor_pointer{
    cursor: pointer;
}
.bg_yash{
    background: #fff;
    box-shadow: 0px 0px 40px #00000029;
    border-radius: 10px;
    padding: 5px 15px;
}
.btn-custom-cls{
    background: #3A3A3A;
    border-radius: 22px;
    padding: 10px;
    border: 0;
    font-size: 14px;
}
.btn-custom-icon{
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
}
.disabled_cursor_pointer{
    cursor: no-drop;
}
.verify-not-All-cls{
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 5px;
}
.border_red{
    border: 1px solid #D30C00;
}
.border_green{
    border: 1px solid green;
}
.verify-All-cls{
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 5px;
    border: 1px solid green;
    color: green;
    cursor: pointer;
}
.add-street-span{
    border: 1px solid green;
    border-radius: 5px;
    padding: 6px 6px;
    font-size: 12px;
    color: green;
    cursor: pointer;
}
.add-street-span i{
    background-color: green;
    color: #fff;
    padding: 4px 7px;
    border-radius: 5px;
}
.form-label-block{
    display: block;
}
.add-new-house-span i{
    border: 1px solid green;
    padding: 3px 5px;
    border-radius: 20px;
}
.collapse-cls{
    border: 1px solid #000;
    padding: 10px 15px;
    cursor: pointer;
}
input::placeholder{
    font-size: 14px;
}
.flex_block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.text-decoration-none{
    text-decoration: none;
}
.undo-cls{
    background-color: #E52C1D;
    color: #fff;
    padding: 5px 12px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}
.loader_cls{
    width: 20px;
    height: 20px;
}
.loader1_cls{
    width: 25px;
    height: 25px;
    position: relative;
    top: -32px;
}
.nav-link{
    font-weight: bold;
}
.form_check_size input{
    width: 1.2em;
    height: 1.2em;
    top: -4px;
    position: relative;
}
.form_check_size label{
   margin-left: 8px !important;
}
.voterDtsAlignMentCls{
    position: relative;
    top: -24px;
    left: -11px;
}
.booth_mapping_icon{
    background: #fff;
    border-radius: 5px;
    padding: 7px 15px;
    position: relative;
    top: -4px;
}
.booth_mapping_icon1{
    background: #ddd;
    border-radius: 5px;
    padding: 7px 15px;
    position: relative;
    top: 0px;
    left: 132%;
}
.table_scroll{
    height: 250px;
    overflow-y: scroll;
}
.f-18{
    font-size: 18px;
}
.flex_block_single_booth{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.width_80{
    width: 85%;
}
.width_20{
    width: 15%;
}
.filter_pos_alignment_cls{
    position: relative;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
}
.color_black{
    color: #000;
}
.color_yellow{
    color: #f9bb37;
}
.pos_booth_icon_left{
    position: relative;
    left: 125%;
}
.loader_cls1{
    width: 30px;
    height: 30px;
}
.m_top50{
    margin-top: 50px;
}