.w_21 {
    width: 20%;
    flex: auto;
  }
  .w_25 {
    width: 25%;
    flex: auto;
  }
  .pad_3{
    padding: 3px;
  }